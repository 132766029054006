import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  snackbar = inject(MatSnackBar);
  openSnackBar(message: string, ms?: number) {
    if (ms) {
      this.snackbar.open(message, 'OK', { duration: ms });
    } else {
      this.snackbar.open(message, 'OK');
    }
  }
}
