import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  signInWithPopup,
  user,
} from '@angular/fire/auth';
import { MLUser } from '../interfaces/user';
import { Router } from '@angular/router';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  auth: Auth = inject(Auth);
  firestore = inject(Firestore);
  router: Router = inject(Router);
  snackbarService = inject(SnackbarService);
  user$ = user(this.auth);
  userSignal: WritableSignal<MLUser | null | undefined> = signal(undefined);

  apiUrl = 'https://musikläraren.se/api';

  signUpWithGoogle(role: string): Promise<void> {
    return signInWithPopup(this.auth, new GoogleAuthProvider()).then((res) => {
      const user = res.user;
      const updateData = {
        role: role,
      };

      let headers: HttpHeaders;

      res.user
        .getIdToken()
        .then((token) => {
          const authHeaders = {
            'Content-Type': 'application/json',
            Authorization: token,
          };
          headers = new HttpHeaders(authHeaders);
        })
        .catch((error) => {
          console.log('Could not get token', error);
        })
        .finally(() => {
          res.user.getIdTokenResult(true).then((tokenResult) => {
            const creationDate = parseInt(tokenResult.authTime) * 1000;
            const now = Date.now();
            const timeDiff = now - creationDate;
            const isJustCreated = timeDiff < 10000;
            const userRole = tokenResult.claims['role'];
            const options = { headers: headers };
            if (!isJustCreated) {
              this.router.navigate(
                userRole === 'student' ? ['elev/hem'] : ['larare/hem']
              );
            } else {
              this.http
                .put(this.apiUrl + '/update/' + user.uid, updateData, options)
                .subscribe({
                  next: (data) => {
                    console.log('Role updated successfully: ', data);
                    res.user.getIdTokenResult(true).then((tokenResult) => {
                      console.log(tokenResult);
                    });
                  },
                  error: (error) => {
                    console.error('Error updating role: ', error);
                  },
                  complete: () => {
                    /* Completion callback */
                  },
                });
            }
          });
        });

      this.addUser(user.uid, role)
        .then(() =>
          this.router.navigate(
            role === 'student' ? ['elev/hem'] : ['larare/hem']
          )
        )
        .catch((error) => {
          console.error('Error adding user: ', error);
        });
    });
  }

  signInWithGoogle(userRole: string) {
    signInWithPopup(this.auth, new GoogleAuthProvider()).then(
      (userCredential) => {
        const user = userCredential.user;
        let role: string | undefined | unknown;
        user
          .getIdTokenResult()
          .then((tokenResult) => {
            role = tokenResult.claims['role'];

            if (role === undefined || role === null) {
              this.snackbarService.openSnackBar(
                'Vi hittade tyvärr inte användaren. Skapa ett konto för att komma igång.'
              );
              user
                .delete()
                .then(() => {
                  this.userSignal.set(undefined);
                  sessionStorage.clear();
                })
                .finally(() => {
                  this.router.navigate(
                    userRole === 'student' ? ['/elev-start'] : ['/']
                  );
                });
            } else {
              this.router.navigate(
                role === 'student' ? ['/elev/hem'] : ['/larare/hem']
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  }

  async signOut(role: string) {
    await this.auth.signOut().finally(() => {
      this.userSignal.set(undefined);
      sessionStorage.clear();
      this.router.navigate(role === 'student' ? ['/elev-start'] : ['/start']);
    });
  }

  async addUser(uid: string, role: string) {
    const data = {
      uid,
      role,
    };
    await setDoc(doc(this.firestore, 'Users', uid), data);
  }

  updateUser(uid: string, data: Partial<MLUser>) {
    return setDoc(doc(this.firestore, 'Users', uid), data);
  }
}
